<!-- InstructionsModal.vue -->
<template>
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-content card card-style">
        <div class="content">
          <h4 class="text-xl font-bold mb-4">സകാത് അപേക്ഷകർ ശ്രദ്ധിക്കേണ്ട പ്രധാന കാര്യങ്ങൾ</h4>
          <hr>
          <div class="instruction-list">
            <div class="instruction-item">
              <span class="number">1.</span>
              <p>ഈ എളിയ സകാത് പദ്ധതി കോഴിക്കോട് റയിൽവേപാതക്ക് പടിഞ്ഞാറ് പ്രദേശങ്ങ ളിൽ സ്ഥിരതാമസമുള്ള (വെള്ളയിൽ മുതൽ മാത്തോട്ടം വരെ) സാമ്പത്തിക-ബുദ്ദിമുട്ട് അനുഭവിക്കുന്ന സകാത്തിന് അർഹരായ വ്യക്തികൾ/കുടുംബങ്ങൾ മാത്രമേ അപേക്ഷകിക്കേണ്ടതുള്ളൂ.</p>
            </div>
            
            <div class="instruction-item">
              <span class="number">2.</span>
              <p>ബേങ്ക് പലിശ ഇടപാടുമായി ബന്ധപ്പെട്ട ആവശ്യങ്ങൾക്കുള്ള അപേക്ഷകൾ സ്വീകരിക്കുന്നതല്ല</p>
            </div>
            
            <div class="instruction-item">
              <span class="number">3.</span>
              <p>ഒരു വീട്ടിൽ നിന്ന് ഒന്നിൽ കൂടുതൽ അപേക്ഷകൾ പാടില്ല. എന്നാൽ ഒരു അപേക്ഷയിൽ തന്നെ വിവിധ സഹായങ്ങൾ ഓപ്‌ഷൻ ✅ നൽകാവുന്നതാണ്</p>
            </div>
            
            <div class="instruction-item">
              <span class="number">4.</span>
              <p>താമസ സ്ഥലം/ അഡ്രസ്സ് തെളിയിക്കുന്നതിനായി ആധാർകാർഡ് കോപ്പി ഓൺലൈൻ ആയി അപ്‌ലോഡ് ചെയ്യേണ്ടതാണ്</p>
            </div>
            
            <div class="instruction-item">
              <span class="number">5.</span>
              <p class="font-bold">രജിസ്റ്റർ, പേഴ്സണൽ ഡാറ്റാ, ഡിപെൻഡൻസ് ഡാറ്റാ, അപേക്ഷ സമർപ്പിക്കൽ തുടങ്ങി 4 steps നിർബന്ധമായും പൂർത്തിയാക്കുക</p>
            </div>
          </div>
          
          <button 
            @click="closeModal"
            class="btn btn-full btn-m mt-4 color-white rounded-s gradient-green border-0 font-700 text-uppercase"
          >
            മനസ്സിലായി
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InstructionsModal',
    data() {
      return {
        isVisible: true
      }
    },
    methods: {
      closeModal() {
        this.isVisible = false
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  .modal-content {
    background: white;
    max-width: 600px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 8px;
  }
  
  .instruction-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .instruction-item {
    display: flex;
    gap: 0.5rem;
  }
  
  .number {
    font-weight: bold;
    min-width: 1.5rem;
  }
  
  /* Add support for Malayalam font */
  .modal-content {
    font-family: 'Manjari', 'Noto Sans Malayalam', sans-serif;
  }
  </style>