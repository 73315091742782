import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store/store';  // Vuex for state management
// import PublicView from '../pages/PublicPage.vue';
// import HomeView from '../pages/HomePage.vue';
import FeaturesView from '../pages/FeaturesPage.vue';
import ProfileView from '../pages/ProfilePage.vue';
import SearchView from '../pages/SearchPage.vue';
import SettingsView from '../pages/SettingsPage.vue';
import LoginPage from '../pages/auth/LoginPage.vue';
import SignUpPage from '../pages/auth/SignUpPage.vue';
import ApplicationForm from '../pages/application/AppForm.vue';
import AboutPage from '../pages/about/AboutPage.vue';
import FaqPage from '../pages/FaqPage.vue';
import SurveyPage from '../pages/SurveyPage.vue';
import ApplicationCreate from '../pages/application/AddApp.vue';
import ApplicationDetails from '../pages/application/AppDetails.vue';
import SubmittedApplication from '../components/SubmittedApplication.vue';

// SurveyForms
import SurveyForm from '../pages/surveyForms/SurveyForm.vue';


const routes = [
  {
    path: '/',
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      if (isAuthenticated) {
        next('/homeview');
      } else {
        next();
      }
    },
    component: () => import('../pages/PublicPage.vue')
  },
  {
    path: '/homeview',
    component: () => import('../pages/HomePage.vue'),
    meta: { requiresAuth: true }
  },
  { path: '/features', component: FeaturesView, meta: { requiresAuth: true } },
  { path: '/profile', component: ProfileView, meta: { requiresAuth: true } },
  { path: '/search', component: SearchView, meta: { requiresAuth: true } },
  { path: '/settings', component: SettingsView, meta: { requiresAuth: true } },
  { path: '/login', component: LoginPage },
  { path: '/signup', component: SignUpPage },
  { path: '/appform', component: ApplicationForm, meta: { requiresAuth: true } },
  { path: '/aboutPage', component: AboutPage },
  { path: '/faqPage', component: FaqPage },
  { path: '/survey', component: SurveyPage, meta: { requiresAuth: true } },
  { path: '/addApp', component: ApplicationCreate, meta: { requiresAuth: true } },
  { path: '/appdetails', component: ApplicationDetails, meta: { requiresAuth: true } },

  { 
    path: '/appform', 
    name: 'CreateApplication',
    component: ApplicationForm, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/appform/:id',
    name: 'EditApplication',
    component: ApplicationForm,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/submitted-application/:id',
    name: 'SubmittedApplication',
    component: SubmittedApplication,
    meta: { requiresAuth: true },
    props: true,
  },

  { path: '/surveyA', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 1 } },
  { path: '/surveyB', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 2 } },
  { path: '/surveyC', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 3 } },
  { path: '/surveyD', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 4 } },
  { path: '/surveyE', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 5 } },
  { path: '/surveyF', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 6 } },
  { path: '/surveyG', component: SurveyForm, meta: { requiresAuth: true }, props: { surveyId: 1, sectionId: 7 } },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.path === '/login' && isAuthenticated) {
    next('/homeview');
  } else {
    next();
  }
});

export default router;