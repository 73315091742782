<!-- ProfilePage.vue -->
<template>
  <div class="profile-container">
    <div class="content-wrapper">
      <!-- Profile Header -->
      <div class="profile-header">
        <div class="profile-avatar-wrapper">
          <img class="profile-avatar"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQNvWDvQb_rCtRL-p_w329CtzHmfzfWP0FIw&s"
            alt="Profile Picture" />
        </div>
        <div class="profile-info">
          <h1 class="profile-name">
            {{ userName }}
          </h1>
          <div class="profile-contact">
            <p class="profile-phone">
              {{ userPhoneNo }}
            </p>
          </div>
        </div>
      </div>

      <!-- Profile Tabs -->
      <div class="profile-buttons">
        <button :class="{ active: activeTab === 'personal' }" @click="switchTab('personal')">
          Personal Details
        </button>
        <button :class="{ active: activeTab === 'address' }" @click="switchTab('address')">
          Address Details
        </button>
        <button :class="{ active: activeTab === 'dependencies' }" @click="switchTab('dependencies')">
          Dependencies
        </button>
      </div>

      <!-- Profile Content -->
      <div class="profile-content">
        <!-- Personal Details Tab -->
        <div v-if="activeTab === 'personal'">
          <div v-if="loading" class="loading-state">
            <i class="fas fa-spinner fa-spin"></i>
            <p>Loading personal details...</p>
          </div>
          <div v-else-if="errorMessage" class="error-state">
            <i class="fas fa-exclamation-circle"></i>
            <p>{{ errorMessage }}</p>
          </div>
          <div v-else>
            <div class="details-header">
              <h2>Personal Details</h2>
              <button class="edit-btn" @click="
                beneficiary.length > 0
                  ? showEditBeneficiaryModal()
                  : showAddBeneficiaryModal()
                ">
                <i class="fas fa-edit"></i>
                <span>{{ beneficiary.length > 0 ? 'Edit' : 'Add' }}</span>
              </button>
            </div>

            <div class="details-grid">
              <div class="detail-card">
                <div class="detail-content">
                  <label>പേര്</label>
                  <h3>{{ displayBeneficiary.name }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>വയസ്സ്</label>
                  <h3>{{ displayBeneficiary.age }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>ആധാർ നമ്പർ</label>
                  <h3>{{ displayBeneficiary.aadhaar_number }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>മൊബൈൽ ഫോൺ നമ്പർ ( 1 )</label>
                  <h3>{{ displayBeneficiary.mobile_number }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>മൊബൈൽ ഫോൺ നമ്പർ ( 2 )</label>
                  <h3>
                    {{ displayBeneficiary.mobile_number_2 || "Not provided" }}
                  </h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>ജോലി</label>
                  <h3>{{ displayBeneficiary.job }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>ലാന്റ് മാർക്ക്</label>
                  <h3>{{ displayBeneficiary.landmark }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>വിദ്യാഭ്യാസ യോഗ്യത</label>
                  <h3>{{ displayBeneficiary.education }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>വിവാഹിത(ൻ)</label>
                  <h3>{{ displayBeneficiary.married ? "അതെ" : "അല്ല" }}</h3>
                </div>
              </div>

              <div class="detail-card" v-if="displayBeneficiary.married">
                <div class="detail-content">
                  <label>വിവാഹിത(ൻ) എങ്കിൽ</label>
                  <h3>{{ displayBeneficiary.spouse }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Address Details Tab -->
        <div v-if="activeTab === 'address'">
          <div v-if="loading" class="loading-state">
            <i class="fas fa-spinner fa-spin"></i>
            <p>Loading address details...</p>
          </div>
          <div v-else-if="errorMessage" class="error-state">
            <i class="fas fa-exclamation-circle"></i>
            <p>{{ errorMessage }}</p>
          </div>
          <div v-else>
            <div class="details-header">
              <h2>Address Details</h2>
              <button class="edit-btn" @click="
                beneficiary.length > 0
                  ? showEditBeneficiaryModal()
                  : showAddBeneficiaryModal()
                ">
                <i class="fas fa-edit"></i>
                <span>{{ beneficiary.length > 0 ? 'Edit' : 'Add' }}</span>
              </button>
            </div>

            <div class="details-grid">
              <div class="detail-card">
                <div class="detail-content">
                  <label>വീട്ടുപേര്</label>
                  <h3>{{ displayBeneficiary.house_name }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>വാർഡ് നമ്പർ</label>
                  <h3>{{ displayBeneficiary.ward_no }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>റസി. നമ്പർ</label>
                  <h3>{{ displayBeneficiary.residential_number }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>ഇലക്ട്രിക് പോസ്റ്റ് നമ്പർ</label>
                  <h3>{{ displayBeneficiary.electric_post_number }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>റോഡ്</label>
                  <h3>{{ displayBeneficiary.road_name }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>സ്ഥലം</label>
                  <h3>{{ displayBeneficiary.place }}</h3>
                </div>
              </div>

              <div class="detail-card">
                <div class="detail-content">
                  <label>പോസ്റ്റ് ഓഫീസ്</label>
                  <h3>{{ displayBeneficiary.post_office }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Beneficiary Modal -->
        <div v-if="showBeneficiaryModal" class="modal-overlay">
          <div class="modal-content">
            <button class="close-button" @click="closeBeneficiaryModal">
              <i class="fas fa-times"></i>
            </button>
            <h2>Update Details</h2>
            <form @submit.prevent="submitBeneficiary">
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.name" class="form-control validate-name" id="form1"
                  placeholder="Name" />
                <label for="form1" class="color-dark font-600 text-uppercase">പേര്</label>
                <span v-if="errors.name" class="text-danger">{{
                  errors.name[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="number" v-model="newBeneficiary.age" class="form-control validate-name" placeholder="Age"
                  min="1" max="120" />
                <label for="form1" class="color-dark font-600 text-uppercase">വയസ്സ്</label>
                <span v-if="errors.age" class="text-danger">{{
                  errors.age[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.aadhaar_number" class="form-control validate-name"
                  placeholder="Aadhaar Number" maxlength="12" @input="validateAadhaar" />
                <label for="form1" class="color-dark font-600 text-uppercase">ആധാർ നമ്പർ</label>
                <span v-if="errors.aadhaar_number" class="text-danger">
                  {{ errors.aadhaar_number[0] }} <!-- Display the error message -->
                </span>
              </div>

              <!-- New Aadhaar Card Upload Field -->
              <!-- <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="file" @change="handleAadhaarUpload" accept="image/*,.pdf"
                  class="form-control validate-name" id="aadhaar_upload" />
                <label for="aadhaar_upload" class="color-dark font-600 text-uppercase">ആധാർ കാർഡ് കോപ്പി</label>
                <span v-if="errors.aadhaar_file" class="text-danger">{{
                  errors.aadhaar_file[0]
                }}</span>
                <div v-if="newBeneficiary.aadhaar_file" class="mt-2">
                  <div class="d-flex align-items-center">
                    <span class="me-2">Uploaded file:</span>
                    <span class="text-highlight">{{ newBeneficiary.aadhaar_file.name }}</span>
                    <button type="button" @click="removeAadhaarFile" class="btn btn-icon ms-2">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div> -->

              <!-- Primary Mobile Number -->
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.mobile_number" class="form-control validate-name"
                  placeholder="+91 Mobile Number" maxlength="10" pattern="[0-9]{10}"
                  title="Please enter a 10-digit Indian mobile number." required />
                <label for="form1" class="color-dark font-600 text-uppercase">
                  മൊബൈൽ ഫോൺ നമ്പർ ( 1 )
                </label>
                <span v-if="errors.mobile_number" class="text-danger">
                  {{ errors.mobile_number[0] }}
                </span>
              </div>

              <!-- Optional Mobile Number -->
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.mobile_number_2" class="form-control validate-name"
                  placeholder="+91 Mobile Number 2 (Optional)" maxlength="10" pattern="[0-9]{10}"
                  title="Please enter a 10-digit Indian mobile number." />
                <label for="form1" class="color-dark font-600 text-uppercase">
                  മൊബൈൽ ഫോൺ നമ്പർ ( 2 )
                </label>
                <em>(optional)</em>
              </div>


              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.job" class="form-control validate-name" placeholder="Job" />
                <label for="form1" class="color-dark font-600 text-uppercase">ജോലി</label>
                <span v-if="errors.job" class="text-danger">{{
                  errors.job[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.house_name" class="form-control validate-name"
                  placeholder="House Name" />
                <label for="form1" class="color-dark font-600 text-uppercase">വീട്ടുപേര്</label>
                <span v-if="errors.house_name" class="text-danger">{{
                  errors.house_name[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="number" v-model="newBeneficiary.ward_no" class="form-control validate-name"
                  placeholder="Ward No" min="1" />
                <label for="form1" class="color-dark font-600 text-uppercase">വാർഡ് നമ്പർ</label>
                <span v-if="errors.ward_no" class="text-danger">{{
                  errors.ward_no[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.residential_number" class="form-control validate-name"
                  placeholder="Residential Number" />
                <label for="form1" class="color-dark font-600 text-uppercase">റസി. നമ്പർ</label>
                <span v-if="errors.residential_number" class="text-danger">{{
                  errors.residential_number[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.electric_post_number" class="form-control validate-name"
                  placeholder="Electric Post Number" />
                <label for="form1" class="color-dark font-600 text-uppercase">ഇലക്ട്രിക്ക് പോസ്റ്റ് നമ്പർ</label>
                <span v-if="errors.electric_post_number" class="text-danger">{{
                  errors.electric_post_number[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.road_name" class="form-control validate-name"
                  placeholder="Road Name" />
                <label for="form1" class="color-dark font-600 text-uppercase">റോഡ്</label>
                <span v-if="errors.road_name" class="text-danger">{{
                  errors.road_name[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.place" class="form-control validate-name"
                  placeholder="Place" />
                <label for="form1" class="color-dark font-600 text-uppercase">സ്ഥലം</label>
                <span v-if="errors.place" class="text-danger">{{
                  errors.place[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.post_office" class="form-control validate-name"
                  placeholder="Post Office" />
                <label for="form1" class="color-dark font-600 text-uppercase">പോസ്റ്റ് ഓഫീസ്</label>
                <span v-if="errors.post_office" class="text-danger">{{
                  errors.post_office[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="text" v-model="newBeneficiary.landmark" class="form-control validate-name"
                  placeholder="Landmark" />
                <label for="form1" class="color-dark font-600 text-uppercase">ലാന്റ് മാർക്ക്</label>
                <span v-if="errors.landmark" class="text-danger">{{
                  errors.landmark[0]
                }}</span>
              </div>

              <!-- <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <select v-model="newBeneficiary.wing_benefiting_from" :disabled="isUpdating &&
                  (beneficiary[0]?.amount_benefiting_from === 0 ||
                    beneficiary[0]?.amount_benefiting_from === null)
                  ">
                  <option value="">വിഭാഗം തിരഞ്ഞെടു��്കുക</option>
                  <option value="Education">വിദ്യാഭ്യാസം</option>
                  <option value="Health">ആരോഗ്യം</option>
                  <option value="Livelihood">ജീവനോപാധി</option>
                  <option value="Other">മറ്റുള്ളവ</option>
                </select>
                <label for="form1" class="color-dark font-600 text-uppercase">സഹായം ലഭിക്കുന്ന വിങ്ങ്</label>
                <span v-if="errors.wing_benefiting_from" class="text-danger">{{
                  errors.wing_benefiting_from[0]
                }}</span>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <input type="number" v-model.number="newBeneficiary.amount_benefiting_from" :disabled="isUpdating &&
                  (beneficiary[0]?.amount_benefiting_from === 0 ||
                    beneficiary[0]?.amount_benefiting_from === null)
                  " class="form-control validate-name" placeholder="Amount Benefiting From" />
                <label for="form1" class="color-dark font-600 text-uppercase">സഹായം ലഭിക്കുന്ന തുക</label>
                <span v-if="errors.amount_benefiting_from" class="text-danger">{{ errors.amount_benefiting_from[0]
                  }}</span>
              </div> -->

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <select v-model="newBeneficiary.married" class="form-control">
                  <option :value="false">അല്ല</option>
                  <option :value="true">അതെ</option>
                </select>
                <label for="form1" class="color-dark font-600 text-uppercase">വിവാഹിത(ൻ)</label>
                <span v-if="errors.married" class="text-danger">{{
                  errors.married[0]
                }}</span>
              </div>

              <div v-if="newBeneficiary.married === true"
                class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <select v-model="newBeneficiary.spouse" class="form-control">
                  <option value="">പങ്കാളിയുടെ നില തിരഞ്ഞെടുക്കുക</option>
                  <option value="alive">ജീവിച്ചിരിപ്പുണ്ട്</option>
                  <option value="passed away">മരണപ്പെട്ടു</option>
                  <option value="divorced">വിവാഹമോചിതൻ</option>
                  <option value="widow">വിധവ</option>
                  <option value="living separately">
                    വേർപെട്ടു ജീവിക്കുന്നു
                  </option>
                </select>
                <label for="form1" class="color-dark font-600 text-uppercase">വിവാഹിത(ൻ) എങ്കിൽ</label>
              </div>

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <select v-model="newBeneficiary.education">
                  <option value="">വിദ്യാഭ്യാസം തിരഞ്ഞെടുക്കുക</option>
                  <option value="illiterate">നിരക്ഷകൻ</option>
                  <option value="lp">എൽ.പി.</option>
                  <option value="up">യു.പി.</option>
                  <option value="high school">ഹൈസ്കൂൾ</option>
                  <option value="plus two">പ്ലസ്ടു</option>
                  <option value="graduation">ബിരുദം</option>
                  <option value="post graduation">ബിരുദാനന്തര ബിരുദം</option>
                </select>
                <label for="form1" class="color-dark font-600 text-uppercase">വിദ്യാഭ്യാസ യോഗ്യത</label>
                <span v-if="errors.education" class="text-danger">{{
                  errors.education[0]
                }}</span>
              </div>

              <button type="submit"
                class="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-highlight mt-4">
                Update
              </button>
            </form>
          </div>
        </div>
        <!-- Dependencies Tab -->
        <div v-if="activeTab === 'dependencies'">
          <div class="d-flex justify-content-between mb-4">
            <h5 class="dependent-title">ആശ്രിതരുടെ വിവരങ്ങൾ</h5>
            <button class="btn btn-primary custom-add-btn" @click="showAddModal">
              Add
            </button>
          </div>

          <hr />

          <!-- Dependents Listing -->
          <div v-if="loading" class="loading-state">
            <i class="fas fa-spinner fa-spin"></i>
            <p>Loading dependencies...</p>
          </div>
          <div v-else-if="error">{{ error }}</div>
          <div v-else>
            <div v-if="dependents.length === 0" class="no-dependents-message">
              <p>Dependent is not added</p>
            </div>
            <div v-else>
              <div v-for="(dependent, index) in dependents" :key="dependent.id">
                <a :data-bs-toggle="'collapse'" :href="'#category-' + (index + 1)" aria-expanded="false"
                  :aria-controls="'category-' + (index + 1)" class="no-effect mb-2">
                  <div class="content">
                    <div class="d-flex mb-n1">
                      <div class="align-self-center">
                        <h1 class="pe-3 font-40 font-900 opacity-30">
                          {{ String(index + 1).padStart(2, "0") }}
                        </h1>
                      </div>
                      <div class="align-self-center">
                        <h4>{{ dependent.name }}</h4>
                        <p class="opacity-80 font-10 mt-n2">Tap here to Expand</p>
                      </div>
                      <div class="ms-auto align-self-center text-center opacity-70">
                        <i class="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i><br />
                      </div>
                    </div>
                  </div>
                </a>
                <div class="collapse" :id="'category-' + (index + 1)">
                  <div class="card">
                    <div class="content">
                      <div class="list-group list-custom-large">
                        <!-- Dependent Details -->
                        <a class="external-link">
                          <i class="fa fa-user bg-user rounded-sm"></i>
                          <span class="mb-n1 font-12">പേര് (ആധാർ പ്രകാരം)</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.name
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-user bg-user rounded-sm"></i>
                          <span class="mb-n1 font-12">മൊബൈൽ ഫോൺ നമ്പർ</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.mobile_number
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fas fa-id-card bg-user rounded-sm"></i> <!-- Changed to Aadhaar-related icon -->
                          <span class="mb-n1 font-12">ആധാർ നമ്പർ</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.aadhaar_number
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-calendar-alt rounded-sm"></i>
                          <span class="mb-n1 font-12">വയസ്സ്</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.age
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-briefcase rounded-sm"></i>
                          <span class="mb-n1 font-12">ജോലി</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.job
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-graduation-cap rounded-sm"></i>
                          <span class="mb-n1 font-12">പഠനം ( ക്ലാസ് )</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.education
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-money-bill rounded-sm"></i>
                          <span class="mb-n1 font-12">മാസ വരുമാനം</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.monthly_earnings
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-users rounded-sm"></i>
                          <span class="mb-n1 font-12">അപേക്ഷകനു മായുള്ള ബന്ധം</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.relation
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-ring rounded-sm"></i>
                          <span class="mb-n1 font-12">വിവാഹിത/ൻ</span>
                          <strong class="mb-n1 font-15">{{
                            dependent.married ? "അതെ" : "അല്ല"
                          }}</strong>
                        </a>
                        <a class="external-link">
                          <i class="fa fa-home rounded-sm"></i>
                          <span class="mb-n1 font-12">ഒപ്പം താമസിക്കുന്നു / ഇല്ല
                          </span>
                          <strong class="mb-n1 font-15">{{
                            dependent.living_separately ? "ഉണ്ട്" : "ഇല്ല"
                          }}</strong>
                        </a>

                        <hr />

                        <div class="d-flex mt-3" style="gap: 10px">
                          <button
                            class="btn btn-m btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-dark-light"
                            @click="showEditModal(dependent)">
                            Edit
                          </button>
                          <button
                            class="btn btn-m btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-red-dark"
                            @click="confirmDeleteDependent(dependent.id)">
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="index < dependents.length - 1" />
              </div>
            </div>
          </div>

          <!-- Add Dependent Modal -->
          <div v-if="showModal" class="modal-overlay">
            <div class="modal-content">
              <button class="close-button" @click="closeModal">
                <i class="fas fa-times"></i>
              </button>
              <h2>{{ editingDependent ? "Edit" : "Add New" }} Dependent</h2>
              <form @submit.prevent="submitDependent">
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <input type="text" class="form-control validate-name" placeholder="പേര് ഇംഗ്ലീഷ്ൽ ടൈപ്പ് ചെയ്യുക"
                    v-model="newDependent.name" />
                  <label for="form1" class="color-dark font-600 text-uppercase">പേര് (ആധാർ പ്രകാരം)</label>
                  <span v-if="errors.name" class="error-message">{{
                    errors.name[0]
                  }}</span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <input type="text" v-model="newDependent.mobile_number" class="form-control validate-name"
                    placeholder="+91 Mobile Number" maxlength="10" pattern="[0-9]{10}"
                    title="Please enter a 10-digit Indian mobile number." required />
                  <label for="form1" class="color-dark font-600 text-uppercase">
                    മൊബൈൽ ഫോൺ നമ്പർ
                  </label>
                  <span v-if="errors.mobile_number" class="text-danger">
                    {{ errors.mobile_number[0] }}
                  </span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <input type="text" v-model="newDependent.aadhaar_number" class="form-control validate-name"
                    placeholder="Aadhaar Number" maxlength="12" @input="validateAadhaar" />
                  <label for="form1" class="color-dark font-600 text-uppercase">ആധാർ നമ്പർ</label>
                  <span v-if="errors.aadhaar_number" class="error-message">{{ errors.aadhaar_number[0] }}</span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <input type="number" class="form-control validate-age" placeholder="Age" v-model="newDependent.age" />
                  <label for="form1" class="color-dark font-600 text-uppercase">വയസ്സ്</label>
                  <span v-if="errors.age" class="error-message">{{
                    errors.age[0]
                  }}</span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <input type="text" class="form-control validate-job" placeholder="Job" v-model="newDependent.job" />
                  <label for="form1" class="color-dark font-600 text-uppercase">ജോലി</label>
                  <span v-if="errors.job" class="error-message">{{
                    errors.job[0]
                  }}</span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <select v-model="newDependent.education">
                    <option value="">വിദ്യാഭ്യാസം തിരഞ്ഞെടുക്കുക</option>
                    <option value="illiterate">നിരക്ഷകൻ</option>
                    <option value="lp">എൽ.പി.</option>
                    <option value="up">യു.പി.</option>
                    <option value="high school">ഹൈസ്കൂൾ</option>
                    <option value="plus two">പ്ലസ്ടു</option>
                    <option value="graduation">ബിരുദം</option>
                    <option value="post graduation">ബിരുദാനന്തര ബിരുദം</option>
                  </select>
                  <label for="form1" class="color-dark font-600 text-uppercase">വിദ്യാഭ്യാസ യോഗ്യത</label>
                  <span v-if="errors.education" class="text-danger">{{
                    errors.education[0]
                  }}</span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <input type="number" class="form-control validate-earnings" id="form5"
                    placeholder="Monthly Earnings (0 if no income)" v-model="newDependent.monthly_earnings" min="0"
                    step="any" />
                  <label for="form1" class="color-dark font-600 text-uppercase">മാസ വരുമാനം</label>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <select v-model="newDependent.relation">
                    <option value="">തിരഞ്ഞെടുക്കുക</option>
                    <option value="son">Son</option>
                    <option value="daughter">Daughter.</option>
                    <option value="father">Father</option>
                    <option value="mother">Mother</option>
                    <option value="grand mother">Grand Mother</option>
                    <option value="grand father">Grand Father</option>
                    <option value="grand child">Grand Child</option>
                    <option value="brother">Brother</option>
                    <option value="sister">Sister</option>
                    <option value="sister in-law">Sister in-law</option>
                    <option value="bother in-law">Brother in-law</option>
                    <option value="niece">Niece</option>
                    <option value="nephew">Nephew</option>
                  </select>
                  <label for="form1" class="color-dark font-600 text-uppercase">അപേക്ഷകനു മായുള്ള ബന്ധം</label>
                  <span v-if="errors.relation" class="text-danger">{{
                    errors.relation[0]
                  }}</span>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <select class="form-control validate-select" id="marriedStatus" v-model="newDependent.married">
                    <option>Select Option</option>
                    <option :value="true">അതെ</option>
                    <option :value="false">അല്ല</option>
                  </select>
                  <label for="form1" class="color-dark font-600 text-uppercase">വിവാഹിത/ൻ</label>
                  <span class="validation-text"></span>
                </div>

                <!-- Living Status Dropdown - Shows only if married is true -->
                <div v-if="newDependent.married"
                  class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                  <select class="form-control validate-select" id="livingStatus" v-model="newDependent.living_status">
                    <option value="" disabled selected>Select Living Status</option>
                    <option value="with_family">ഒപ്പം താമസം</option>
                    <option value="spouse_house">വേറെ സ്വന്തം/ഭാര്യാ വീട്ടിൽ</option>
                    <option value="rental">വേറെ വാടക വീട്ടിൽ</option>
                  </select>
                  <label for="form1" class="color-dark font-600 text-uppercase">താമസ സ്ഥിതി</label>
                  <span class="validation-text"></span>
                </div>
                <!-- <div class="form-check icon-check">
                  <input class="form-check-input" type="checkbox" value="" id="check2"
                    v-model="newDependent.living_separately" />
                  <label class="form-check-label" for="check2">ഒപ്പം താമസിക്കുന്നു / ഇല്ല</label>
                  <i class="icon-check-1 fa fa-square color-gray-dark font-16"></i>
                  <i class="icon-check-2 fa fa-check-square font-16 color-highlight"></i>
                </div> -->
                <button type="submit"
                  class="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-highlight mt-4">
                  {{ editingDependent ? "Update" : "Add" }} Dependent
                </button>
              </form>
            </div>
          </div>
        </div>
        <div v-if="isSurveyor">
          <h2>Assigned Applications</h2>
          <ul>
            <li v-for="application in applications" :key="application.id">
              {{ application.name }} <!-- Adjust according to your application structure -->
            </li>
          </ul>
        </div>

        <div v-if="isBeneficiary">
          <h2>Your Beneficiary Details</h2>
          <!-- Render beneficiary-specific details here -->
        </div>
      </div>
    </div>

    <!-- Footer with Logout Button -->
    <div class="profile-footer">
      <button class="logout-button" @click="logout">
        <i class="fas fa-sign-out-alt"></i> Logout
      </button>
    </div>
  </div>
</template>

<!-- ProfilePage.vue -->

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      activeTab: "personal",
      isDropdownOpen: false,
      dependents: [],
      loading: false,
      errorMessage: null,
      showModal: false,
      showBeneficiaryModal: false,
      newDependent: this.getEmptyDependent(),
      newBeneficiary: this.getEmptyBeneficiary(),
      editingDependent: null,
      errors: {},
      beneficiary: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(['userRole']),
    currentBeneficiary() {
      return this.$store.getters.beneficiary || {};
    },
    userName() {
      // Prioritize user data, then beneficiary data, then default
      return (
        this.user?.name ||
        this.beneficiary[0]?.name ||
        this.currentBeneficiary?.name ||
        "Name not added"
      );
    },
    userPhoneNo() {
      // Prioritize beneficiary data over user data
      return (
        this.beneficiary?.[0]?.mobile_number ||
        this.user?.phone_no ||
        this.currentBeneficiary?.mobile_number ||
        "Phone Number not added"
      );
    },
    isUpdating() {
      return this.beneficiary?.length > 0 && this.beneficiary[0]?.id;
    },
    displayBeneficiary() {
      // If no beneficiary data exists, return an object with default user values
      if (!this.beneficiary?.length) {
        return {
          name: this.userName,
          mobile_number: this.userPhoneNo,
        };
      }
      return this.beneficiary[0];
    },
    applications() {
      return this.$store.getters.applications;
    },
    isSurveyor() {
      return this.userRole && this.userRole === 'surveyor'; // Add null check
    },
    isBeneficiary() {
      return this.userRole && this.userRole === 'beneficiary'; // Add null check
    }
  },
  methods: {
    ...mapActions(["logout", "setUser"]),

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    async updateBeneficiary(updatedBeneficiary) {
      if (!updatedBeneficiary) return;
      await this.$store.dispatch("setBeneficiary", updatedBeneficiary);
    },

    async updateDependents(updatedDependents) {
      // ... update logic ...
      await this.$store.dispatch("setDependents", updatedDependents);
    },

    showAddBeneficiaryModal() {
      // Initialize with user data
      this.newBeneficiary = {
        ...this.getEmptyBeneficiary(),
        name: this.userName !== "Name not added" ? this.userName : "",
        mobile_number:
          this.userPhoneNo !== "Phone Number not added" ? this.userPhoneNo : "",
      };
      this.showBeneficiaryModal = true;
    },

    showEditBeneficiaryModal() {
      const currentData = this.beneficiary?.[0] || {};

      this.newBeneficiary = {
        ...this.getEmptyBeneficiary(),
        ...currentData,
        // Ensure we preserve the current values or fall back to user data
        name: currentData.name || this.userName,
        mobile_number: currentData.mobile_number || this.userPhoneNo,
      };

      this.showBeneficiaryModal = true;
    },
    closeBeneficiaryModal() {
      this.showBeneficiaryModal = false;
      this.resetBeneficiaryForm();
    },

    resetBeneficiaryForm() {
      this.newBeneficiary = this.getEmptyBeneficiary();
      this.errors = {};
    },

    // Add helper method for alerts
    async showAlert({
      title,
      text,
      icon = "success",
      confirmButtonText = "OK",
    }) {
      return await Swal.fire({
        title,
        text,
        icon,
        confirmButtonText,
        confirmButtonColor: "#3085d6",
      });
    },

    // Add helper method for confirmations
    async showConfirm({ title, text, icon = "warning" }) {
      return await Swal.fire({
        title,
        text,
        icon,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      });
    },

    getEmptyBeneficiary() {
      return {
        name: this.userName !== "Name not added" ? this.userName : "",
        mobile_number:
          this.userPhoneNo !== "Phone Number not added" ? this.userPhoneNo : "",
        age: "",
        aadhaar_number: "",
        mobile_number_2: "",
        job: "",
        house_name: "",
        ward_no: "",
        residential_number: "",
        electric_post_number: "",
        road_name: "",
        place: "",
        post_office: "",
        landmark: "",
        married: false, // Initialize as boolean
        spouse: "",
        education: "",
        wing_benefiting_from: "",
        amount_benefiting_from: null,
      };
    },

    getEmptyDependent() {
      return {
        name: "",
        mobile_number: "",
        aadhaar_number: "",
        age: null,
        job: "",
        education: "",
        monthly_earnings: null,
        relation: "",
        married: false,
        living_separately: false,
      };
    },

    async submitBeneficiary() {
      if (this.loading) return;

      try {
        this.loading = true;
        this.errors = {};

        // Validate required fields
        const requiredFields = ["name", "mobile_number"];
        const missingFields = requiredFields.filter(
          (field) => !this.newBeneficiary[field]
        );

        if (missingFields.length > 0) {
          throw new Error(
            `Missing required fields: ${missingFields.join(", ")}`
          );
        }

        // Format beneficiary data
        const formattedBeneficiary = {
          ...this.newBeneficiary,
          married: Boolean(this.newBeneficiary.married),
          age: this.newBeneficiary.age ? Number(this.newBeneficiary.age) : null,
          amount_benefiting_from: this.newBeneficiary.amount_benefiting_from
            ? Number(this.newBeneficiary.amount_benefiting_from)
            : null,
          name: this.newBeneficiary.name?.trim(),
          mobile_number: this.newBeneficiary.mobile_number?.trim(),
          mobile_number_2: this.newBeneficiary.mobile_number_2?.trim() || null,
        };

        let response;
        const currentBeneficiary = this.beneficiary?.[0];
        const isUpdate = Boolean(currentBeneficiary?.id);

        if (isUpdate) {
          // Update existing beneficiary
          const updateData = { ...formattedBeneficiary };

          // Remove unchanged fields
          Object.keys(updateData).forEach((key) => {
            if (
              updateData[key] === currentBeneficiary[key] ||
              (updateData[key] === null && !currentBeneficiary[key])
            ) {
              delete updateData[key];
            }
          });

          // Ensure required fields
          requiredFields.forEach((field) => {
            if (!updateData[field]) {
              updateData[field] = currentBeneficiary[field];
            }
          });

          if (Object.keys(updateData).length === 0) {
            this.closeBeneficiaryModal();
            return;
          }

          response = await api.updateBeneficiary(currentBeneficiary.id, formattedBeneficiary);
        } else {
          response = await api.createBeneficiary(formattedBeneficiary);
        }

        // Modified response handling
        if (!response?.data) {
          throw new Error("Invalid response from server");
        }

        const { data, message } = response.data;

        // More flexible data handling
        let beneficiaryData;
        if (Array.isArray(data)) {
          beneficiaryData = data;
        } else if (data && typeof data === "object") {
          beneficiaryData = [data];
        } else {
          // If no data but response is successful, use the formatted data
          beneficiaryData = [formattedBeneficiary];
        }

        // Update local state if we have valid data
        if (beneficiaryData.length > 0) {
          this.beneficiary = beneficiaryData;

          // Ensure we have the minimum required data for store updates
          const beneficiaryForStore = {
            ...beneficiaryData[0],
            name: beneficiaryData[0].name || formattedBeneficiary.name,
            mobile_number:
              beneficiaryData[0].mobile_number ||
              formattedBeneficiary.mobile_number,
          };

          // Update store
          await Promise.all([
            this.$store.dispatch("setBeneficiary", beneficiaryForStore),
            this.$store.dispatch("setUser", {
              name: beneficiaryForStore.name,
              phone_no: beneficiaryForStore.mobile_number,
            }),
          ]);
        }

        // Show success message
        await this.showAlert({
          title: "Success",
          text:
            message ||
            `Beneficiary ${isUpdate ? "updated" : "created"} successfully`,
        });

        // Close modal and refresh data
        this.closeBeneficiaryModal();
        await this.fetchBeneficiary();
      } catch (error) {
        console.error("Error in submitBeneficiary:", error);

        let errorMessage = "Failed to save beneficiary. Please try again.";

        if (error.response?.data?.errors) {
          this.errors = error.response.data.errors;
          const firstError = Object.values(error.response.data.errors)[0];
          errorMessage = Array.isArray(firstError) ? firstError[0] : firstError;
        } else if (error.message) {
          this.errors = { general: [error.message] };
          errorMessage = error.message;
        }

        await this.showAlert({
          title: "Error",
          text: errorMessage,
          icon: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    switchTab(tab) {
      this.activeTab = tab;
      this.isDropdownOpen = false;
      if (tab === "personal" || tab === "address") {
        this.fetchBeneficiary();
      }
      if (tab === "dependencies") {
        this.loading = true; // Set loading state for dependents
        if (this.dependents.length === 0) {
          this.fetchDependents();
        } else {
          this.loading = false; // Reset loading state if dependents are already fetched
        }
      }
    },

    async fetchBeneficiary() {
      this.loading = true;
      this.errorMessage = null;

      try {
        const response = await api.getBeneficiaries();
        if (response?.data) {
          const processedData = Array.isArray(response.data)
            ? response.data[0]
            : response.data;

          if (processedData) {
            const beneficiaryData = {
              ...processedData,
              married: Boolean(processedData.married),
              // Preserve existing data or use user data as fallback
              name: processedData.name || this.userName,
              mobile_number: processedData.mobile_number || this.userPhoneNo,
            };

            await this.$store.dispatch("setBeneficiary", beneficiaryData);
            this.beneficiary = [beneficiaryData];
          } else {
            console.warn("No beneficiary data found");
            this.beneficiary = [];
            // When no beneficiary exists, set default user data
            await this.$store.dispatch("setBeneficiary", {
              name: this.userName,
              mobile_number: this.userPhoneNo,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching beneficiary data:", error);
        this.errorMessage = error.message || "Failed to load beneficiary data";
        this.beneficiary = [];
        await this.$store.dispatch("setBeneficiary", null);
      } finally {
        this.loading = false;
      }
    },

    async deleteBeneficiary() {
      this.loading = true;
      this.errorMessage = null;

      try {
        const beneficiaryId = this.beneficiary[0].id;
        const response = await api.deleteBeneficiary(beneficiaryId);

        if (response.data.message === "Beneficiary deleted successfully") {
          this.beneficiary = [];
          this.$store.dispatch("setBeneficiary", {});
          await this.showAlert({
            title: "Deleted!",
            text: response.data.message,
          });
        } else {
          throw new Error("Unexpected response from server");
        }
      } catch (error) {
        console.error("Error deleting beneficiary:", error);
        await this.showAlert({
          title: "Error",
          text: "Failed to delete beneficiary. Please try again.",
          icon: "error",
        });
      } finally {
        this.loading = false;
        this.fetchBeneficiary();
      }
    },

    async confirmDeleteBeneficiary() {
      const result = await this.showConfirm({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
      });

      if (result.isConfirmed) {
        await this.deleteBeneficiary();
      }
    },

    async fetchDependents() {
      this.loading = true;
      try {
        const response = await api.getDependents();
        this.dependents = response.data;
      } catch (err) {
        this.errorMessage = "Failed to load dependents";
      } finally {
        this.loading = false;
      }
    },

    showAddModal() {
      this.showModal = true;
    },

    showEditModal(dependent) {
      this.editingDependent = dependent;
      this.newDependent = { ...dependent };
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.resetForm();
      this.editingDependent = null;
    },

    async updateDependent() {
      try {
        this.loading = true;
        const response = await api.updateDependent(
          this.editingDependent.id,
          this.newDependent
        );
        if (
          response &&
          response.data &&
          response.data.message === "BeneficiaryDependent updated successfully"
        ) {
          const index = this.dependents.findIndex(
            (dep) => dep.id === this.editingDependent.id
          );
          if (index !== -1 && response.data.data) {
            this.dependents.splice(index, 1, response.data.data);
          }

          await this.fetchDependents();
          this.closeModal();
          await this.showAlert({
            title: "Success",
            text: "Dependent updated successfully!",
          });
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (err) {
        console.error("Error updating dependent:", err);
        this.errors = err.response?.data?.errors || {
          general: ["An error occurred."],
        };
        await this.showAlert({
          title: "Error",
          text: "Failed to update dependent. Please try again.",
          icon: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async confirmDeleteDependent(id) {
      const result = await this.showConfirm({
        title: "Delete Dependent",
        text: "Are you sure you want to delete this dependent?",
      });

      if (result.isConfirmed) {
        await this.deleteDependent(id);
      }
    },

    async deleteDependent(id) {
      try {
        this.loading = true;
        const response = await api.deleteDependent(id);
        if (
          response.data.message === "BeneficiaryDependent deleted successfully"
        ) {
          this.dependents = this.dependents.filter((dep) => dep.id !== id);
          await this.showAlert({
            title: "Success",
            text: "Dependent deleted successfully!",
          });
        }
      } catch (err) {
        console.error("Error deleting dependent:", err);
        await this.showAlert({
          title: "Error",
          text: "Failed to delete dependent. Please try again.",
          icon: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async submitDependent() {
      if (this.editingDependent) {
        await this.updateDependent();
      } else {
        await this.createDependent();
      }
    },

    async createDependent() {
      try {
        this.loading = true;
        this.errors = {};

        const dependentWithBeneficiaryId = {
          ...this.newDependent,
          beneficiary_id: this.beneficiary[0]?.id,
        };

        const response = await api.createDependent(dependentWithBeneficiaryId);
        if (
          response.data.message === "BeneficiaryDependent created successfully"
        ) {
          this.dependents.push(response.data.data);
          this.resetForm();
          await this.showAlert({
            title: "Success",
            text: "Dependent added successfully!",
          });
          this.closeModal();
        }
      } catch (err) {
        console.error("Error creating dependent:", err);
        this.errors = err.response?.data?.errors || {
          general: ["An error occurred."],
        };
        await this.showAlert({
          title: "Error",
          text: "Failed to create dependent. Please try again.",
          icon: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.newDependent = this.getEmptyDependent();
      this.errors = {};
    },
    validateAadhaar() {
      const aadhaar = this.newBeneficiary.aadhaar_number;
      if (!/^\d{0,12}$/.test(aadhaar)) {
        this.newBeneficiary.aadhaar_number = aadhaar.slice(0, 12);
      }

      if (aadhaar.length !== 12) {
        this.errors.aadhaar_number = 'Aadhaar number must be exactly 12 digits.';
      } else {
        this.errors.aadhaar_number = null;
      }
    },
    async fetchApplications() {
      try {
        const response = await api.getApplications();
        this.applications = response.data;
      } catch (error) {
        console.error("Error fetching applications:", error);
        this.errorMessage = error.message || "Failed to load applications";
      }
    }
  },
  async created() {
    if (this.$store.getters.isAuthenticated) {
      await this.fetchBeneficiary();

      // Set user data if not already set
      if (!this.user?.name || !this.user?.phone_no) {
        this.$store.dispatch("setUser", {
          name: this.userName !== "Name not added" ? this.userName : "",
          phone_no:
            this.userPhoneNo !== "Phone Number not added"
              ? this.userPhoneNo
              : "",
        });
      }
    }
    if (this.isSurveyor) {
      await this.fetchApplications(); // Fetch applications if the user is a surveyor
    }
  },
  watch: {
    'beneficiary': {
      deep: true,
      handler(newVal) {
        if (newVal?.[0]?.mobile_number) {
          this.$store.dispatch("setUser", {
            ...this.user,
            phone_no: newVal[0].mobile_number
          });
        }
      }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

.profile-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 80px;
}

.content-wrapper {
  flex: 1;
}

.profile-header {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #ffffff 0%, #dcdcdc 100%);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  gap: 20px;
}

.profile-avatar-wrapper {
  flex-shrink: 0;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  object-fit: cover;
}

.profile-avatar:hover {
  transform: scale(1.05);
}

.profile-info {
  flex-grow: 1;
}

.profile-name {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
}

.profile-contact {
  display: flex;
  flex-wrap: wrap;
}

.profile-phone,
.profile-email {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.profile-phone i,
.profile-email i {
  margin-right: 10px;
}

.profile-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  line-height: 15px;
}

.profile-buttons button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 12px;
  margin: 0 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.profile-buttons button.active {
  background-color: #2575fc;
  color: white;
}

.profile-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.profile-content h2 {
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.profile-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.detail-item label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
  font-weight: 500;
}

.detail-item input {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
}

.detail-item input:disabled {
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.detail-item input:focus {
  outline: none;
  border-color: #2575fc;
  box-shadow: 0 0 0 2px rgba(37, 117, 252, 0.1);
}

.edit-button {
  background-color: #2575fc;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.edit-button:hover {
  background-color: #1a68e6;
}

.dependencies-list {
  list-style-type: none;
  padding: 0;
}

.dependencies-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.dependencies-list li:last-child {
  border-bottom: none;
}

.dependency-name {
  font-weight: 500;
}

.dependency-version {
  color: #666;
}

.dependency-status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.dependency-status.up-to-date {
  background-color: #e6f4ea;
  color: #34a853;
}

.dependency-status.update-available {
  background-color: #fef6e0;
  color: #fbbc04;
}

.profile-footer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.logout-button {
  background: linear-gradient(45deg, #ff6b6b, #be1616);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.logout-button i {
  margin-right: 10px;
}

.logout-button:hover {
  background: linear-gradient(45deg, #d65353, #a61010);
}

.details-buttons {
  display: flex;
  gap: 10px;
  /* Space between buttons */
}

.details-buttons button {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-buttons button.active {
  background-color: #007bff;
  color: white;
}

.details-buttons button:hover {
  background-color: #e0e0e0;
}

.details-buttons button.active:hover {
  background-color: #0056b3;
}

.form-check.is-invalid .form-check-input {
  border-color: #dc3545;
}

.form-check .text-danger {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}

/* Add some space between the checkbox and the error message */
.form-check.is-invalid {
  padding-bottom: 1.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-content h2 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.input-style {
  position: relative;
  margin-bottom: 20px;
}

.input-style p {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  font-weight: 500;
}

.input-style .fa,
.input-style i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 16px;
  pointer-events: none;
  /* Ensures the icon doesn't interfere with input interaction */
}

.input-style input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  /* Increased left padding to accommodate icon */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;

  color: #333;
  height: 45px;
  /* Fixed height for consistency */
  line-height: 45px;
  /* Matches height for vertical centering */
}

.input-style select {
  padding: 0px 0px 0px 40px;
}

.input-style p+.fa,
.input-style p+i {
  top: calc(59% + 10px);
  /* Adjusts for the label height */
}

.input-style input::placeholder {
  color: #999;
  line-height: normal;
  /* Ensures placeholder is vertically centered */
}

.input-style input:focus,
.input-style select:focus {
  outline: none;
  border-color: #2575fc;
  box-shadow: 0 0 0 2px rgba(37, 117, 252, 0.1);
}

/* Adjust select element to match input styling */
.input-style select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  /* Space for the dropdown arrow */
}

/* Disabled state styling */
.input-style input:disabled,
.input-style select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Error state */
.input-style .text-danger {
  color: #ff0019;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.text-danger {
  color: #ff0019;
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
  display: block;
}

.btn-full {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #2575fc;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-full:hover {
  background-color: #1a68e6;
}

.form-group {
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  /* Border around the input */
  border-radius: 8px;
  /* Rounded input fields */
  font-size: 1rem;
  margin-top: 5px;
  background-color: #f9f9f9;
  /* Light background for input */
}

.input-field:focus {
  outline: none;
  border-color: #007bff;
  /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  /* Shadow on focus */
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
  /* Rounded buttons */
  border: none;
  cursor: pointer;
  gap: 10px;
}

.btn-save {
  background-color: #007bff;
  color: white;
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

.btn:active {
  transform: scale(0.98);
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

.btn-outline-primary {
  width: 40px;
  height: 40px;
  background-color: #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .profile-header {
    flex-direction: row;
    align-items: center;
    padding: 15px;
  }

  .profile-container {
    padding-bottom: 100px;
  }

  .modal-content {
    padding: 16px;
    width: 95%;
  }

  .input-style input,
  .input-style select {
    font-size: 13px;
  }

  .profile-avatar {
    width: 70px;
    height: 70px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-contact {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-phone,
  .profile-email {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .profile-tabs {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .profile-tabs button {
    font-size: 14px;
    padding: 8px 16px;
    margin: 0 10px 10px 0;
  }

  .profile-content {
    padding: 20px 15px;
  }

  .profile-details {
    grid-template-columns: 1fr;
  }

  .profile-info {
    line-height: 0px;
  }
}

.dependent-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.custom-add-btn {
  font-size: 0.875rem;
  /* 14px */
  padding: 0.375rem 1.55rem;
  height: 32px;
  width: 40px;
  line-height: 1;
  border-radius: 6px;
  background-color: #3366ff;
  /* Adjust color to match your design */
  border: none;
}

.custom-add-btn:hover {
  background-color: #2952cc;
  /* Slightly darker shade for hover */
}

/* Adjust spacing between title and button if needed */
.d-flex {
  gap: 1rem;
}

@media (max-width: 480px) {
  .profile-header {
    padding: 10px;
  }

  .profile-avatar {
    width: 60px;
    height: 60px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-contact {
    font-size: 11px;
  }

  .profile-tabs button {
    flex: 1 0 40%;
    font-size: 12px;
    padding: 6px 12px;
  }

  .profile-content {
    padding: 15px 10px;
  }

  .profile-info {
    line-height: 0px;
  }
}

@media (max-width: 360px) {
  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-avatar-wrapper {
    margin: 0 0 10px 0;
  }

  .profile-info {
    width: 100%;
  }

  .profile-contact {
    justify-content: center;
  }

  .profile-info {
    line-height: 0px;
  }
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.details-header h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 0;
}

.edit-btn {
  background: #f8f9fa;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #2575fc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
}

.edit-btn i {
  margin-right: 5px;
}

.edit-btn:hover {
  background: #2575fc;
  color: white;
  transform: translateY(-2px);
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
}

.detail-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
}

.detail-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.detail-icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2575fc;
}

.detail-icon i {
  font-size: 1.5rem;
}

.detail-content {
  flex: 1;
}

.detail-content label {
  display: block;
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
}

.detail-content h3 {
  margin: 0;
  font-size: 1.125rem;
  color: #333;
  font-weight: 600;
}

.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: #6c757d;
}

.loading-state i,
.error-state i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-state i {
  color: #dc3545;
}

.loading-state p,
.error-state p {
  margin: 0;
  font-size: 1rem;
}

.no-dependents-message {
  color: #dc3545;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .details-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .detail-card {
    padding: 1rem;
  }

  .detail-icon {
    width: 40px;
    height: 40px;
  }

  .detail-icon i {
    font-size: 1.25rem;
  }

  .details-header h2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .detail-content label {
    font-size: 0.75rem;
  }

  .detail-content h3 {
    font-size: 1rem;
  }
}
</style>
