<template>
  <div id="page">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <div class="content mb-0">
          <h3 class="signup-title">Sign Up</h3>
          <div v-if="successMessage" class="alert alert-success">
            {{ successMessage }}
          </div>
          <div class="modal-content">
            <form @submit.prevent="handleSignUp">
              <!-- Name -->

              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <label for="name" class="color-blue-dark font-600 text-uppercase">Name</label>
                <input type="text" id="name" v-model="formData.name" class="form-control"
                  placeholder="Enter your name" />
                <span class="error" v-if="errors.name">{{ errors.name }}</span>
              </div>


              <!-- Username -->
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <label for="username" class="color-blue-dark font-600 text-uppercase">Username</label>
                <input type="text" id="username" v-model="formData.user_name" class="form-control"
                  placeholder="Enter your username" />
                <span class="error" v-if="errors.user_name">{{ Array.isArray(errors.user_name) ? errors.user_name[0] :
                  errors.user_name }}</span>
              </div>


              <!-- Phone Number -->
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <label for="phone" class="color-blue-dark font-600 text-uppercase">Phone Number</label>
                <div class="flex items-center">
                  <input type="tel" id="phone" v-model="formData.phone_no" class="form-control flex-grow"
                    placeholder="Enter your Indian mobile number " maxlength="10" pattern="[0-9]{10}" />
                </div>
                <span class="error" v-if="errors.phone_no">
                  {{ Array.isArray(errors.phone_no) ? errors.phone_no[0] : errors.phone_no }}
                </span>
              </div>

              <!-- Password -->
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <label for="password" class="color-blue-dark font-600 text-uppercase">Password</label>
                <input type="password" id="password" v-model="formData.password" class="form-control"
                  placeholder="Enter your password" />
                <span class="error" v-if="errors.password">{{ Array.isArray(errors.password) ? errors.password[0] :
                  errors.password }}</span>
              </div>

              <!-- Confirm Password -->
              <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
                <label for="password_confirmation" class="color-blue-dark font-600 text-uppercase">Confirm
                  Password</label>
                <input type="password" id="password_confirmation" class="form-control"
                  v-model="formData.password_confirmation" placeholder="Confirm your password" />
                <span class="error" v-if="errors.password_confirmation">{{ Array.isArray(errors.password_confirmation) ?
                  errors.password_confirmation[0] : errors.password_confirmation }}</span>
              </div>

              <hr>

              <button type="submit"
                class="btn btn-m btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-blue-dark">Sign
                Up</button>
            </form>
          </div>
        </div>
      </div>
      <p class="login-link">Already have an account? <router-link :to="{ path: '/login' }">Sign In</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      formData: {
        name: '',
        user_name: '',
        phone_no: '',
        password: '',
        password_confirmation: ''
      },
      errors: {},
      successMessage: ''  // Add success message data property
    };
  },
  methods: {
    ...mapActions(['login']),
    async handleSignUp() {
      this.errors = {};
      this.successMessage = '';  // Clear any previous success message

      // Basic front-end validation
      for (const [key, value] of Object.entries(this.formData)) {
        if (!value) {
          this.errors[key] = `${key.replace('_', ' ')} is required`;
        }
      }
      if (this.formData.password !== this.formData.password_confirmation) {
        this.errors.password_confirmation = 'Passwords do not match';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

       // Updated phone number validation
       if (this.formData.phone_no) {
        // Remove any existing phone-related errors
        delete this.errors.phone_no;

        // Validate 10-digit phone number
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(this.formData.phone_no)) {
          this.errors.phone_no = 'Please enter a valid 10-digit mobile number';
        }
      }

      // Modify form data to include +91 before sending
      const formDataToSend = {
        ...this.formData,
        phone_no: '+91' + this.formData.phone_no
      };

      try {
        const response = await api.signup(formDataToSend);
        console.log('Signup successful:', response.data);

        // Show the success message
        this.successMessage = 'Registered Successfully';

        // Wait for 2 seconds, then redirect to login
        setTimeout(() => {
          this.$router.push('/login');
        }, 2000);

      } catch (error) {
        console.error('Signup error:', error);
        if (error.response && error.response.data) {
          // Handle both object and array error formats
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            // If the error response is direct (not nested under 'errors')
            this.errors = error.response.data;
          }
        } else {
          this.errors.general = 'An error occurred during signup. Please try again.';
        }
      }
    }
  }
};
</script>

<style scoped>
.signup-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.input-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 48%;
}

.input-group {
  flex-grow: 1;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.875rem;
  color: #333;
}

.input-group input:focus,
.input-group select:focus {
  outline: none;
  border-color: #007bff;
}

.custom-checkbox {
  display: none;
}

/* Custom checkbox container */
.custom-checkbox-container {
  display: flex;
  align-items: center;
  /* This centers items vertically */
  cursor: pointer;
  flex: 1;
}

/* Create a custom checkbox using a pseudo-element */
.checkbox-label::before {
  content: '';
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #5c5c5c;
  border-radius: 10px;
  /* For rounded corners */
  background-color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  vertical-align: middle;
}

/* When the checkbox is checked, change the background color and add a checkmark */
.custom-checkbox:checked+.checkbox-label::before {
  background-color: #007bff;
  border-color: #007bff;
  position: relative;
}

/* Create a checkmark using another pseudo-element */
.custom-checkbox:checked+.checkbox-label::after {
  content: '\2714';
  /* Unicode checkmark */
  position: absolute;
  left: 4px;
  top: 2px;
  color: white;
  font-size: 14px;
}

/* Customize the label text */
.checkbox-label {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  user-select: none;
  vertical-align: middle;
  /* Ensures the label is vertically aligned with the custom checkbox */
}

.signup-button:hover {
  background-color: #0056b3;
}

.login-link {
  text-align: center;
  font-size: 0.875rem;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.error {
  color: red;
}
</style>
