<!-- AppForm.vue -->
<template>
  <div>
    <div id="page">
      <div class="page-content header-clear-small">
        <div class="card card-style">
          <div class="content">
            <h3>{{ isEditing ? 'Edit Application' : 'അപേക്ഷാ ഫോം' }}</h3>

            <form @submit.prevent="submitForm">
              <div class="divider mb-3"></div>

              <h5 class="font-14">1. JCC യുടെ സഹായം ലഭിക്കുന്നുണ്ടോ?</h5>
              <div class="form-check interest-check scale-switch">
                <input class="form-check-input" type="radio" name="existing_beneficiary" :value="1"
                  id="existing_beneficiary_yes" v-model="formData.existing_beneficiary" />
                <label class="form-check-label rounded-m"
                  :class="{ 'highlight-selected': formData.existing_beneficiary === 1 }" for="existing_beneficiary_yes">
                  ഉണ്ട്
                </label>
              </div>

              <div class="form-check interest-check scale-switch">
                <input class="form-check-input" type="radio" name="existing_beneficiary" :value="0"
                  id="existing_beneficiary_no" v-model="formData.existing_beneficiary" />
                <label class="form-check-label rounded-m"
                  :class="{ 'highlight-selected': formData.existing_beneficiary === 0 }" for="existing_beneficiary_no">
                  ഇല്ല
                </label>
              </div>

              <div class="divider mb-3"></div>
              <div v-if="formData.existing_beneficiary === 1">
                <h5 class="font-14">2. സഹായം ലഭിക്കുന്ന വിങ്ങും തുകയും</h5>
                <div class="input-style has-borders no-icon validate-field mb-4">
                  <select type="text" class="form-control validate-text" id="wing_benefiting_from"
                    v-model="formData.wing_benefiting_from" placeholder="സഹായം ലഭിക്കുന്ന വിങ്">
                    <option value="">വിഭാഗം തിരഞ്ഞെടുക്കുക</option>
                    <option value="Education">വിദ്യാഭ്യാസം</option>
                    <option value="Health">ആരോഗ്യം</option>
                    <option value="Livelihood">ജീവനോപാധി</option>
                    <option value="Other">മറ്റുള്ളവ</option>
                  </select>
                </div>
                <div class="input-style has-borders no-icon validate-field mb-4">
                  <input type="number" class="form-control validate-text" id="amount_benefiting_from"
                    v-model="formData.amount_benefiting_from" placeholder="ലഭിക്കുന്ന തുക">
                </div>
              </div>

              <div class="divider mb-3"></div>
              <h5 class="font-14">3. നിലവിൽ വീട്</h5>
              <div class="input-style has-borders no-icon validate-field mb-4">
                <select class="form-control validate-select" id="house_type" v-model="formData.house_type">
                  <option value="">-- തിരഞ്ഞെടുക്കുക --</option>
                  <option value="Owned">സ്വാന്തം പേരിൽ</option>
                  <option value="Rented">വാടക വീട്ടിൽ</option>
                  <option value="Lease">പാട്ടത്തിന്</option>
                  <option value="Extended Family">കൂട്ടുകുടുംബം</option>
                  <option value="Sibling House">സഹോദരന്റെ/സഹോദരിയുടെ വീട്ടിൽ</option>
                  <option value="mortgage">പണയം</option>
                  <option value="other">മറ്റുള്ളവ</option>
                </select>
              </div>
              <div class="input-style has-borders no-icon validate-field mb-4" v-if="formData.house_type === 'other'">
                <input type="text" class="form-control validate-text" v-model="formData.otherHouseDetails"
                  placeholder="മറ്റുള്ളവ വിശദമാക്കുക">
              </div>

              <div class="divider mb-3"></div>
              <h5 class="font-14">4. സക്കാത്ത് സഹായത്തിന് അപേക്ഷിക്കുന്നത്</h5>
              <div class="input-style has-borders no-icon validate-field mb-4">
                <select class="form-control validate-select" id="help_category_id" v-model="formData.help_category_id"
                  required>
                  <option value="">-- തിരഞ്ഞെടുക്കുക --</option>
                  <option v-for="category in helpCategories" :key="category.id" :value="category.id">
                    {{ category.name }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="!formData.help_category_id">
                  Please select a help category
                </div>
              </div>

              <div class="divider mb-3"></div>
              <h5 class="font-14">ഡോക്യുമെന്റ് അപ്ലോഡ്</h5>

              <div class="document-section">
                <!-- Existing Documents Display -->
                <div v-if="isEditing && existingDocuments.length > 0" class="mb-3">
                  <h6 class="font-12 mb-2">Existing Documents:</h6>
                  <div class="existing-documents">
                    <div v-for="(doc, index) in existingDocuments" :key="doc.path"
                      class="document-item mb-2 p-2 border rounded">
                      <div class="d-flex justify-content-between align-items-center">
                        <span class="document-name">{{ getDocumentName(doc.path) }}</span>
                        <div>
                          <!-- Replace button and hidden file input -->
                          <input :id="'replace-file-' + index" type="file" class="d-none"
                            accept="application/pdf,image/*" @change="(e) => handleDocumentReplace(e, index)" />
                          <label :for="'replace-file-' + index" class="btn btn-sm btn-secondary me-2"
                            style="cursor: pointer;">
                            Replace
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Upload Button and New Files Display -->
                <div class="mb-4">
                  <!-- File Upload Button -->
                  <div class="upload-button-wrapper mb-3">
                    <input type="file" id="documents" class="file-upload-input" multiple
                      accept="application/pdf,image/*" @change="handleFileUpload" style="display: none;" />
                    <label for="documents"
                      class="btn btn-full btn-m rounded-s shadow-l bg-dark-light text-uppercase font-900"
                      style="cursor: pointer;">
                      <span class="upload-icon me-2">📁</span>
                      ഫയലുകൾ തിരഞ്ഞെടുക്കുക (Maximum 2)
                    </label>
                  </div>

                  <!-- New Files Display -->
                  <div v-if="newFiles.length > 0" class="new-files">
                    <h6 class="font-12 mb-2">New Files:</h6>
                    <div class="document-items">
                      <div v-for="(file, index) in newFiles" :key="index" class="document-item mb-2 p-2 border rounded">
                        <div class="d-flex justify-content-between align-items-center">
                          <span class="document-name">{{ file.name }}</span>
                          <button type="button" class="delete-btn" @click="removeNewFile(index)">
                            <i class="fa-solid fa-trash-can"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Maximum Files Warning -->
                  <div v-if="totalDocuments >= 2" class="text-danger mt-2 font-11">
                    Maximum number of documents (2) reached
                  </div>
                </div>
              </div>

              <hr>

              <!-- Submit Button -->
              <button type="submit"
                class="btn btn-full btn-m rounded-s shadow-l bg-highlight text-uppercase font-900 mb-4">
                {{ isEditing ? 'Update Application' : 'അപേക്ഷ സമർപ്പിക്കുക' }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { mapState, mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: ['id'],
  data() {
    return {
      formData: {
        status: 'pending',
        applicant_id: '',
        help_category_id: '',
        user_id: '',
        existing_beneficiary: null,
        wing_benefiting_from: '',
        amount_benefiting_from: null,
        house_type: '',
        otherHouseDetails: '',
        deletedDocumentIds: [],
      },
      newFiles: [],
      existingDocuments: [],
      isEditing: false,
      applicationId: null,
      helpCategories: [
        { id: 1, name: "വീടുമായി ബന്ധപ്പെട്ട" },
        { id: 2, name: "കിഡ്‌നി, ഹൃദയ, കാൻസർ, മറ്റു മാരകരോഗ ചികിത്സ" },
        { id: 3, name: "കടബാധ്യത വീട്ടാൻ" },
        { id: 4, name: "വിദ്യാഭ്യാസത്തിന്" },
        { id: 5, name: "സ്വയം തൊഴിൽ" },
        { id: 6, name: "നിത്യ ജീവിത ചെലവിന്" }
      ],
      // Updated house types mapping to match backend validation
      houseTypes: {
        'Rented': 'വാടക വീട്ടിൽ',
        'Owned': 'സ്വാന്തം പേരിൽ',
        'Lease': 'പാട്ടത്തിന്',
        'Extended Family': 'കൂട്ടുകുടുംബം',
        'Sibling House': 'സഹോദരന്റെ/സഹോദരിയുടെ വീട്ടിൽ',
        'mortgage': 'പണയം',
        'other': 'മറ്റുള്ളവ'
      }
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.user,
      beneficiary: state => state.beneficiary,
      editingApplication: state => state.application.editingApplication
    }),
    ...mapGetters(['isAuthenticated']),
    totalDocuments() {
      return this.existingDocuments.length + this.newFiles.length;
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(user) {
        if (user) {
          this.formData.user_id = user.id;
        }
      }
    },
    beneficiary: {
      immediate: true,
      handler(beneficiary) {
        if (beneficiary) {
          this.formData.applicant_id = beneficiary.id;
          this.formData.house_type = this.mapHouseType(beneficiary.house_type);
          // Initialize existing_beneficiary from beneficiary data
          this.formData.existing_beneficiary = beneficiary.existing_beneficiary;
          if (beneficiary.existing_beneficiary === 1) {
            this.formData.wing_benefiting_from = beneficiary.wing_benefiting_from;
            this.formData.amount_benefiting_from = beneficiary.amount_benefiting_from;
          }
        }
      }
    },
  },
  async created() {
    if (!this.isAuthenticated) {
      this.$router.push('/login');
      return;
    }

    try {
      if (!this.beneficiary) {
        await this.$store.dispatch('fetchBeneficiary');
      }

      if (this.$route.params.id) {
        this.isEditing = true;
        this.applicationId = this.$route.params.id;
        await this.fetchApplication();
      } else {
        this.resetForm();
      }
    } catch (error) {
      console.error('Error initializing form:', error);
      Swal.fire({
        title: 'Error',
        text: 'Error loading application data. Please try refreshing the page.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  },
  methods: {
    ...mapActions(['clearEditingApplication']),
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      if (this.totalDocuments + files.length > 2) {
        Swal.fire({
          title: 'File Upload Limit Exceeded',
          text: 'You can only have a maximum of 2 documents total.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        event.target.value = '';
        return;
      }

      this.newFiles.push(...files);
    },

    handleDocumentReplace(event, index) {
      const file = event.target.files[0];
      if (!file) return;

      // Update the UI to show the new filename directly
      this.existingDocuments[index] = {
        ...this.existingDocuments[index],
        path: file.name, // Update display name
        replacementFile: file // Store the new file
      };

      event.target.value = ''; // Reset input
    },
    removeNewFile(index) {
      this.newFiles.splice(index, 1);
    },

    getDocumentName(path) {
      if (!path) return 'Unknown Document';
      return path.split('/').pop() || 'Unknown Document';
    },

    async removeExistingDocument(document) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'This document will be permanently deleted.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel'
        });

        if (!result.isConfirmed) return;

        if (document.id) {
          // Add to deleted documents array
          this.deletedDocumentIds.push(document.id);
          // Remove from existing documents array
          this.existingDocuments = this.existingDocuments.filter(doc => doc.id !== document.id);
        }

        Swal.fire('Deleted!', 'Document has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting document:', error);
        Swal.fire('Error', 'Failed to delete document.', 'error');
      }
    },
    validateForm() {
      if (!this.isAuthenticated) {
        this.$router.push('/login');
        return false;
      }

      if (!this.formData.applicant_id) {
        Swal.fire({
          title: 'Missing Information',
          text: 'Applicant ID is missing. Please ensure your profile is complete.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return false;
      }

      if (!this.formData.help_category_id) {
        Swal.fire({
          title: 'Missing Information',
          text: 'Please select a help category.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return false;
      }

      return true;
    },

    mapHouseType(type) {
      const mappings = {
        'Rented': 'Rented',
        'Own': 'Owned',
        'Lease': 'Lease',
        'Extended Family': 'Extended Family',
        'Sibling House': 'Sibling House',
        'mortgage': 'mortgage',
        'other': 'other'
      };
      return mappings[type] || type;
    },

    async fetchApplication() {
      try {
        const response = await api.getApplicationById(this.applicationId);
        let applicationData = Array.isArray(response.data) ? response.data[0] : response.data;

        // Set applicant_id from the application data or beneficiary data
        this.formData.applicant_id = applicationData.applicant_id || applicationData.beneficiary?.id || this.beneficiary?.id;

        // Format documents array - handle both document objects with and without IDs
        if (applicationData.documents && Array.isArray(applicationData.documents)) {
          this.existingDocuments = applicationData.documents.map(doc => {
            // Check if doc is already in the correct format or needs to be formatted
            if (typeof doc === 'object') {
              return {
                id: doc.id || undefined,
                path: doc.path || ''
              };
            }
            return {
              path: doc || ''
            };
          }).filter(doc => doc.path); // Filter out any empty paths
        } else {
          this.existingDocuments = [];
        }

        // Find matching help category - first try exact match
        let categoryId = this.helpCategories.find(
          category => category.name.trim() === applicationData.category?.trim()
        )?.id;

        // If no exact match, try normalized comparison
        if (!categoryId) {
          const normalizedCategory = applicationData.category?.replace(/\s+/g, ' ').trim();
          categoryId = this.helpCategories.find(
            category => category.name.replace(/\s+/g, ' ').trim() === normalizedCategory
          )?.id;
        }

        // Update form data with all fields
        this.formData = {
          ...this.formData,
          status: applicationData.status || 'pending',
          help_category_id: categoryId || '',
          user_id: applicationData.user_id || this.currentUser?.id,
          existing_beneficiary: applicationData.existing_beneficiary,
          wing_benefiting_from: applicationData.wing_benefiting_from || '',
          amount_benefiting_from: applicationData.amount_benefiting_from || null,
          house_type: this.mapHouseType(applicationData.house_type) || '',
          otherHouseDetails: applicationData.otherHouseDetails || '',
        };

        // Log the form data after setting it
        console.log('Form Data after fetch:', this.formData);
        console.log('Existing Documents:', this.existingDocuments);

        if (!categoryId && applicationData.category) {
          console.warn(`Could not map category: "${applicationData.category}" to any existing category ID`);
          Swal.fire({
            title: 'Warning',
            text: 'Could not load the help category correctly. Please select the appropriate category.',
            icon: 'warning',
            confirmButtonText: 'OK'
          });
        }

      } catch (error) {
        console.error('Error fetching application:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error loading application data. Please try refreshing the page.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    async submitForm() {
      if (!this.validateForm()) return;

      try {
        Swal.fire({
          title: 'Submitting Application',
          text: 'Please wait...',
          allowOutsideClick: false,
          didOpen: () => { Swal.showLoading(); }
        });

        const formData = new FormData();

        // Ensure help_category_id is properly converted to a number
        const processedFormData = {
          ...this.formData,
          help_category_id: parseInt(this.formData.help_category_id) || this.formData.help_category_id,
        };

        // Append form fields, ensuring null values are converted to empty strings
        Object.keys(processedFormData).forEach(key => {
          const value = processedFormData[key];
          if (value !== null && value !== undefined) {
            // Convert numbers to strings for FormData
            formData.append(key, value.toString());
          } else {
            formData.append(key, '');
          }
        });

        // Log form data for debugging
        console.log('Form Data before submission:', Object.fromEntries(formData));

        // Append new files
        this.newFiles.forEach((file, index) => {
          formData.append(`documents[${index}]`, file);
        });

        // If editing, append existing document paths
        if (this.isEditing) {
          this.existingDocuments.forEach((doc, index) => {
            formData.append(`existing_documents[${index}]`, doc.path);
          });
        }

        let response;
        if (this.isEditing) {
          response = await api.updateApplicationWithFiles(this.applicationId, formData);
        } else {
          response = await api.createApplicationWithFiles(formData);
        }

        // Log response for debugging
        console.log('API Response:', response);

        Swal.fire({
          title: 'Success!',
          text: `Application ${this.isEditing ? 'updated' : 'submitted'} successfully!`,
          icon: 'success',
          confirmButtonText: 'OK'
        });

        this.clearEditingApplication();
        this.$router.push('/features');
      } catch (error) {
        console.error('Error submitting form:', error);
        const errorMessage = error.response?.data?.errors?.help_category_id?.[0]
          || error.response?.data?.message
          || 'An error occurred while submitting the application.';

        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    resetForm() {
      this.formData = {
        status: 'pending',
        applicant_id: this.beneficiary?.id || '',
        user_id: this.currentUser?.id || '',
        help_category_id: '',
        existing_beneficiary: this.beneficiary?.existing_beneficiary ?? null,
        wing_benefiting_from: this.beneficiary?.wing_benefiting_from || '',
        amount_benefiting_from: this.beneficiary?.amount_benefiting_from || null,
        house_type: this.mapHouseType(this.beneficiary?.house_type) || '',
        otherHouseDetails: '',
      };
      this.newFiles = [];
      this.existingDocuments = [];
      this.applicationId = null;
    },
  }
};
</script>

<style scoped>
.file-upload-wrapper {
  position: relative;
  width: 100%;
}

.file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.document-item {
  background-color: #f8f9fa;
}

.document-name {
  word-break: break-all;
  margin-right: 1rem;
}

.file-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 8px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.upload-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

#file-list {
  max-height: 100px;
  overflow-y: auto;
}

#file-list div {
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.page-content {
  padding-bottom: 80px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.interest-check label {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.highlight-selected {
  background-color: #d1ecf1;
  border: 2px solid #0c5460;
  color: #0c5460;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.swal2-popup {
  font-size: 1.2rem;
}

.document-item {
  background-color: #f8f9fa;
  transition: background-color 0.2s;
}

.document-item:hover {
  background-color: #f1f3f5;
}

.document-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 40px);
  padding-right: 8px;
}

.delete-btn {
  background: none;
  border: none;
  color: #dc3545;
  padding: 4px 8px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.delete-btn:hover {
  color: #bb2d3b;
}

.delete-btn i {
  font-size: 16px;
}

.d-flex {
  width: 100%;
  gap: 8px;
}
</style>