import { createStore } from 'vuex';
import router from '@/router';
import api from '@/services/api';

const store = createStore({
  state: {
    token: localStorage.getItem('token') || null,
    isAuthenticated: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')) || null,
    beneficiary: null,
    dependents: [],
    editingApplication: null,
    applications: [],
    userRole: null,
  },
  
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      state.isAuthenticated = !!token;
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    },
    SET_USER(state, user) {
      state.user = user;
      state.userRole = user.role;
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.removeItem('user');
      }
    },
    SET_BENEFICIARY(state, beneficiary) {
      state.beneficiary = beneficiary;
    },
    SET_DEPENDENTS(state, dependents) {
      state.dependents = dependents;
    },
    SET_EDITING_APPLICATION(state, application) {
      state.editingApplication = application;
    },
    CLEAR_EDITING_APPLICATION(state) {
      state.editingApplication = null;
    },
    SET_APPLICATIONS(state, applications) {
      state.applications = applications;
    },
    UPDATE_APPLICATION(state, updatedApplication) {
      const index = state.applications.findIndex(app => app.id === updatedApplication.id);
      if (index !== -1) {
        state.applications.splice(index, 1, updatedApplication);
      }
    },
    ADD_APPLICATION(state, application) {
      state.applications.unshift(application);
    },
    CLEAR_USER(state) {
      state.user = null;
      state.userRole = null;
    },
  },
  
  actions: {
    async checkAuth({ commit, dispatch }) {
      const token = localStorage.getItem('token');
      if (token) {
        commit('SET_TOKEN', token);
        try {
          const response = await api.verifyToken();
          commit('SET_USER', response.data.user);
          return true;
        } catch (error) {
          dispatch('logout');
          return false;
        }
      }
      return false;
    },
    
    async login({ commit }, credentials) {
      try {
        const response = await api.login(credentials);
        const { token, user } = response.data;
        commit('SET_TOKEN', token);
        commit('SET_USER', user); // Ensure user is not null
        router.push('/homeview');
        return true;
      } catch (error) {
        console.error('Login error:', error);
        return false;
      }
    },
    
    logout({ commit }) {
      commit('CLEAR_USER');
      commit('SET_TOKEN', null);
      commit('SET_BENEFICIARY', null);
      commit('SET_DEPENDENTS', []);
      router.push('/');
    },
    
    setBeneficiary({ commit }, beneficiary) {
      commit('SET_BENEFICIARY', beneficiary);
    },
    
    setDependents({ commit }, dependents) {
      commit('SET_DEPENDENTS', dependents);
    },
    
    async fetchBeneficiary({ commit }) {
      try {
        const response = await api.getBeneficiaries();
        if (response && response.data) {
          const beneficiary = Array.isArray(response.data) ? response.data[0] : response.data;
          commit('SET_BENEFICIARY', beneficiary);
        }
      } catch (error) {
        console.error('Error fetching beneficiary:', error);
      }
    },
    
    async fetchDependents({ commit }) {
      try {
        const response = await api.getDependents();
        if (response && response.data) {
          commit('SET_DEPENDENTS', response.data);
        }
      } catch (error) {
        console.error('Error fetching dependents:', error);
      }
    },
    
    clearEditingApplication({ commit }) {
      commit('CLEAR_EDITING_APPLICATION');
    },
    
    async fetchApplications({ commit }) {
      try {
        const response = await api.getApplications();
        commit('SET_APPLICATIONS', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching applications:', error);
        throw error;
      }
    },
    
    async submitApplication({ commit }, { isEditing, applicationData, applicationId }) {
      try {
        const formData = new FormData();
        Object.keys(applicationData).forEach(key => {
          if (key === 'documents') {
            applicationData.documents.forEach((file, index) => {
              formData.append(`documents[${index}]`, file);
            });
          } else if (applicationData[key] !== null && applicationData[key] !== undefined) {
            formData.append(key, applicationData[key].toString());
          }
        });

        let response;
        if (isEditing) {
          response = await api.updateApplicationWithFiles(applicationId, formData);
          commit('UPDATE_APPLICATION', response.data);
        } else {
          response = await api.createApplicationWithFiles(formData);
          commit('ADD_APPLICATION', response.data);
        }
        commit('CLEAR_EDITING_APPLICATION');
        return response.data;
      } catch (error) {
        console.error('Error submitting application:', error);
        throw error;
      }
    },

    setEditingApplication({ commit }, application) {
      const cleanApplication = {
        ...application,
        documents: application.documents || [],
        help_category_id: parseInt(application.help_category_id) || '',
        existing_beneficiary: application.existing_beneficiary !== null 
          ? parseInt(application.existing_beneficiary) 
          : null,
        amount_benefiting_from: application.amount_benefiting_from !== null 
          ? parseFloat(application.amount_benefiting_from) 
          : null
      };
      commit('SET_EDITING_APPLICATION', cleanApplication);
    }
  },
  
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
    beneficiary: state => state.beneficiary,
    dependents: state => state.dependents,
    applications: state => state.applications,
    editingApplication: state => state.editingApplication,
  }
});

export default store;