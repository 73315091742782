import axios from "axios";

const API_URL = "https://zakath.cloudocz.com/api";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add auth token to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Handle unauthorized access
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.log("Unauthorized access - token may be invalid");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const apiService = {
  // Auth methods
  login(credentials) {
    return api.post("/login", credentials);
  },

  signup(userData) {
    return api.post("/register", userData);
  },

  // Survey form methods
  getSurveyForm(surveyId, sectionId) {
    return api.get(`/survey-forms/${surveyId}/${sectionId}`);
  },

  submitSurveyForm(formData) {
    // Format the answers according to the API requirements
    const answers = formData.answers.map(answer => ({
      question_id: parseInt(answer.question_id),
      answer: answer.answer.toString(),
      section_id: parseInt(answer.section_id)
    }));

    return api.post("/survey-forms", {
      survey_id: parseInt(formData.survey_id),
      section_id: parseInt(formData.section_id),
      answers: answers
    });
  },

  // Dependent methods
  createDependent(dependentData) {
    return api.post("/dependents", dependentData);
  },

  getDependents() {
    return api.get("/dependents");
  },

  updateDependent(id, dependentData) {
    return api.patch(`/dependents/${id}`, dependentData);
  },

  deleteDependent(id) {
    return api.delete(`/dependents/${id}`);
  },

  // Beneficiary methods
  getBeneficiaries() {
    return api.get("/beneficiaries");
  },

  createBeneficiary(beneficiaryData) {
    return api.post("/beneficiaries", beneficiaryData);
  },

  updateBeneficiary(id, beneficiaryData) {
    return api.put(`/beneficiaries/${id}`, beneficiaryData);
  },

  deleteBeneficiary(id) {
    return api.delete(`/beneficiaries/${id}`);
  },

  // Application methods
  getApplications() {
    return api.get("/applications");
  },

  getApplicationById(id) {
    return api.get(`/applications/${id}`);
  },

  async createApplicationWithFiles(applicationData) {
    try {
      return await api.post("/applications", applicationData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error('Error creating application:', error);
      throw error;
    }
  },

  async updateApplicationWithFiles(id, applicationData) {
    try {
      applicationData.append('_method', 'PUT');
      return await api.post(`/applications/${id}`, applicationData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error('Error updating application:', error);
      throw error;
    }
  },

  deleteApplication(id) {
    return api.delete(`/applications/${id}`);
  },

  async updateApplicationDocuments(id, formData) {
    return api.post(`/applications/${id}/update-documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async deleteDocument(id) {
    return api.delete(`/documents/${id}`);
  }
};

export default apiService;