import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './registerServiceWorker'

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
