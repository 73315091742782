<template>
  <div>
    <div id="page">
      <div class="page-content header-clear-small">
        <div class="card card-style">
          <div class="content">
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else-if="!currentSection">No section data available</div>
            <div v-else>
              <!-- Sub-sections -->
              <div v-if="currentSection.sub_sections && currentSection.sub_sections.length > 0">
                <div v-for="(subSection, subIndex) in currentSection.sub_sections" :key="subSection.id"
                  class="sub-section">
                  <h5>{{ subIndex + 1 }}. {{ subSection.name }}</h5>
                  <hr>
                  <!-- Questions in sub-sections -->
                  <div v-if="subSection.questions && subSection.questions.length > 0">
                    <div v-for="(subQuestion, subQuestionIndex) in subSection.questions" :key="subQuestion.id"
                      class="sub-question">
                      <h5 class="font-14">
                        <span class="question-number">{{ getQuestionNumber(subIndex, subQuestionIndex) }}.</span>
                        {{ subQuestion.question }}
                      </h5>

                      <!-- Input fields based on question type -->
                      <div v-if="subQuestion.type === 'select'" class="input-style has-borders no-icon validate-field mb-4">
                        <select class="form-control" 
                          @change="handleAnswerChange(subQuestion.id, $event.target.value)">
                          <option value="" disabled selected>-- Select --</option>
                          <option v-for="option in subQuestion.question_options" :key="option.id" :value="option.value">
                            {{ option.option }}
                          </option>
                        </select>
                      </div>

                      <div v-else-if="subQuestion.type === 'number'"
                        class="input-style has-borders no-icon validate-field mb-4">
                        <input class="form-control" type="number"
                          :value="getAnswer(subQuestion.id)"
                          @input="handleAnswerChange(subQuestion.id, $event.target.value)" />
                      </div>

                      <div v-else-if="subQuestion.type === 'text'"
                        class="input-style has-borders no-icon validate-field mb-4">
                        <input class="form-control" type="text"
                          :value="getAnswer(subQuestion.id)"
                          @input="handleAnswerChange(subQuestion.id, $event.target.value)" />
                      </div>

                      <div v-else-if="subQuestion.type === 'longtext'"
                        class="input-style has-borders no-icon validate-field mb-4">
                        <textarea class="form-control"
                          :value="getAnswer(subQuestion.id)"
                          @input="handleAnswerChange(subQuestion.id, $event.target.value)"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Main Questions -->
              <div v-if="currentSection.questions && currentSection.questions.length > 0" class="section">
                <h5>{{ currentSection.name }}</h5>
                <hr>
                <div v-for="(question, index) in currentSection.questions" :key="question.id" class="question">
                  <h5 class="font-14">
                    <span class="question-number">{{ getMainQuestionNumber(index) }}.</span>
                    {{ question.question }}
                  </h5>

                  <!-- Radio Inputs -->
                  <div v-if="question.type === 'radio' && question.question_options">
                    <div v-for="option in question.question_options" :key="option.id"
                      class="form-check interest-check scale-switch">
                      <input class="form-check-input" type="radio" :id="`${question.id}-${option.id}`"
                        :name="`question-${question.id}`" :value="option.value"
                        :checked="getAnswer(question.id) === option.value"
                        @change="handleAnswerChange(question.id, option.value)" />
                      <label class="form-check-label rounded-m"
                        :class="{ 'highlight-selected': getAnswer(question.id) === option.value }"
                        :for="`${question.id}-${option.id}`">{{ option.option }}</label>
                    </div>
                  </div>

                  <!-- Select Inputs -->
                  <div v-else-if="question.type === 'select' && question.question_options"
                    class="input-style has-borders no-icon validate-field mb-4">
                    <select class="form-control validate-select"
                      :value="getAnswer(question.id)"
                      @change="handleAnswerChange(question.id, $event.target.value)">
                      <option value="" disabled selected>-- Select --</option>
                      <option v-for="option in question.question_options" :key="option.id" :value="option.value">
                        {{ option.option }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <hr>
              <a href="#" @click.prevent="handleSubmit"
                class="btn btn-full btn-m rounded-s shadow-l bg-highlight text-uppercase font-900 mb-4">
                അപേക്ഷ സമർപ്പിക്കുക
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from '@/services/api';

export default {
  name: 'SurveyForm',
  props: {
    surveyId: {
      type: [String, Number],
      required: true
    },
    sectionId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    
    const formData = ref(null);
    const currentSection = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const answers = reactive({});

    const getMainQuestionNumber = (index) => {
      return index + 1;
    };

    const getQuestionNumber = (subIndex, questionIndex) => {
      if (!currentSection.value?.questions) return 0;
      
      const topLevelQuestionsCount = currentSection.value.questions.length;
      let previousSubSectionsQuestionsCount = 0;
      
      if (currentSection.value.sub_sections) {
        for (let i = 0; i < subIndex; i++) {
          previousSubSectionsQuestionsCount += 
            currentSection.value.sub_sections[i]?.questions?.length || 0;
        }
      }
      
      return topLevelQuestionsCount + previousSubSectionsQuestionsCount + questionIndex + 1;
    };

    const handleAnswerChange = (questionId, value) => {
      if (!answers[props.surveyId]) {
        answers[props.surveyId] = {};
      }
      answers[props.surveyId][questionId] = value;
    };

    const getAnswer = (questionId) => {
      return answers[props.surveyId]?.[questionId] ?? '';
    };

    const fetchFormData = async () => {
      try {
        loading.value = true;
        error.value = null;
        
        const response = await api.getSurveyForm(props.surveyId, props.sectionId);
        
        if (!response?.data) {
          throw new Error('Invalid response format');
        }

        const sectionData = response.data;
        
        if (!sectionData || !sectionData.id) {
          throw new Error(`Failed to load section ${props.sectionId}`);
        }

        currentSection.value = sectionData;
        formData.value = { currentSection: sectionData };
      } catch (err) {
        console.error('Error fetching form data:', err);
        error.value = err.response?.data?.message || 'Error fetching form data';
      } finally {
        loading.value = false;
      }
    };

    const handleSubmit = async () => {
      if (!currentSection.value) {
        alert('No section data available');
        return;
      }

      try {
        const allQuestions = [
          ...(currentSection.value.questions || []),
          ...(currentSection.value.sub_sections || []).flatMap(sub => sub.questions || [])
        ];

        const formattedAnswers = Object.entries(answers[props.surveyId] || {})
          .map(([questionId, value]) => {
            const question = allQuestions.find(q => q.id.toString() === questionId);
            if (!question) return null;

            return {
              question_id: parseInt(questionId, 10),
              answer: value?.toString() || '',
              section_id: props.sectionId
            };
          })
          .filter(Boolean);

        if (formattedAnswers.length === 0) {
          alert('Please answer at least one question before submitting.');
          return;
        }

        const payload = {
          survey_id: parseInt(props.surveyId, 10),
          section_id: props.sectionId,
          answers: formattedAnswers
        };

        const response = await api.submitSurveyForm(payload);

        if (response?.data?.status === true) {
          alert('Section submitted successfully!');
          const nextSectionId = props.sectionId + 1;
          if (nextSectionId <= 7) {
            router.push(`/survey${String.fromCharCode(64 + nextSectionId)}`);
          } else {
            router.push('/survey');
          }
        } else {
          throw new Error(response?.data?.message || 'Submission failed');
        }
      } catch (err) {
        console.error('Submission error:', err);
        alert(err.response?.data?.message || 'Error submitting form. Please try again.');
      }
    };

    // Initialize
    fetchFormData();

    // Return
    return {
      formData,
      currentSection,
      loading,
      error,
      answers,
      handleAnswerChange,
      getAnswer,
      getQuestionNumber,
      getMainQuestionNumber,
      handleSubmit,
      isAuthenticated: computed(() => store.getters.isAuthenticated)
    };
  }
};
</script>

<style scoped>
.interest-check label {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.highlight-selected {
  background-color: #d1ecf1;
  border: 2px solid #0c5460;
  color: #0c5460;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.question-number {
  font-weight: bold;
  margin-right: 5px;
}
</style>